
import Hero from '../../components/hero/Hero';
import './Contact.css';
import Img from '../../assets/image/contact-page-img.png'
import { motion } from "framer-motion"
import {FaFacebookF} from 'react-icons/fa'
import{BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsLinkedin} from 'react-icons/bs'
import { useFormik } from "formik"
import { basicSchemas } from "../../schemas/index.js";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';


 //alert for success update and error
 const notifySuccess = () => toast.success('Successfully Send!', {});
 const notifyError = () =>  toast.error('Something went wrong!', {});

const onSubmit = async (values, actions) => {
    // call api here
    let data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("mobile",values.mobile);
    data.append("message", values.message);
  
    axios.post('https://autocardoctor.co.nz/backend/contact_form_submit.php', data)
    .then(response => {
      if(response.data === "Success"){
        notifySuccess();
      }else{
        notifyError();
      }
    })
    .catch(error => {
        console.log(error);
    });
  

    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  };

const Contact = () => {
    const mobile = window.innerWidth <= 500 ? true : false;

    const animationProps = {
        initial: { opacity: 0, x:mobile? 30 :100},
        whileInView: { opacity: 1 ,x:0, delay:0.3},
        transition: { duration: 2}
      };
    
    const animationCommon = {
        initial: { opacity: 0.6, y:75},
        whileInView: { opacity: 1 ,y:0, dealy:0.3},
        transition: { duration: 1,}
    };

    const {values,handleChange,handleBlur,handleSubmit,errors,touched,isSubmitting} = useFormik({
        initialValues:{
            name:"",
            email:"",
            mobile:"",
            message:""
        },
        validationSchema: basicSchemas,
        onSubmit,
    });

    const [loadData, setLoadData] = useState([]);  

    useEffect(()=>{
      loadContactData();
    },[]);
    
    const loadContactData= ()=> {
      axios.post('https://autocardoctor.co.nz/backend/contact_data_load.php')
          .then(response => {
            setLoadData(response.data);
          })
           .catch(error => {
              console.log(error);
          });
    }
    

  return (
    <div  className='contact'>
      <Hero mainTitle="Contact Us" 
      icon1={<FaFacebookF/>} title1="Facebook" 
      icon2={<BsInstagram/>} title2="Instagram" 
      icon3={<BsTwitter/>} title3="Twitter" 
      icon4={<BsLinkedin/>} title4="Linkedin" 
      
      />

      <section className='contact-main'>
        <div className="container">
            <div className="content-main-content">
                <div className="contact-img">
                    <motion.img {...animationProps} src={Img} alt="contact-img" className='img-fluid' />
                </div>
                <div className='side-white-div side-white-1'>
                </div>
                <div className='side-white-div side-white-2'>
                </div>

                <div className="contact-info">
                    <span>Contact Info</span>
                    <span>Our customer support team is available during our business hours to assist you with any inquiries or concerns you may have. Feel free to reach out to us via phone or email, and we'll be happy to help.</span>
                    <div className='row'>
                        <div className="col-md-6 c-info">
                            <span>Contact Us</span>
                            <span>{loadData?.c_mobile}</span>
                        </div>
                        <div className="col-md-6 c-info">
                            <span>Location</span>
                            <span>{loadData?.c_address}</span>
                        </div>
                        <div className="col-md-6 c-info">
                            <span>Hotline</span>
                            <span>{loadData?.c_mobile}</span>
                        </div>
                        <div className="col-md-6 c-info">
                            <span>Email</span>
                            <span>{loadData?.c_email}</span>
                        </div>
                    </div>
                </div>

            <form onSubmit={handleSubmit} autoComplete='off'>
            <motion.div {...animationCommon} className="contact-form">
                <div className='cont-label'>
                    <span>Need  A Hand</span>
                    <span>Make an Appointment</span>
                </div>

                <div className='cont-input'>
                    <input 
                        type="text" 
                        placeholder='Name'
                        className={errors.name && touched.name ? "input-error": ""}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id='name'
                    />
                    <small> {(errors.name && touched.name) && errors.name}</small>
                </div>

                <div className="cont-input">
                    <input 
                        type="email" 
                        placeholder='Email'
                        className={errors.email && touched.email ? "input-error": ""}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id='email'
                    />
                    <small> {(errors.email && touched.email) && errors.email}</small>
                </div>
                <div className="cont-input">
                    <input 
                        type="text" 
                        placeholder='Mobile Number'
                        className={errors.mobile && touched.mobile ? "input-error": ""}
                        value={values.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id='mobile'
                    />
                    <small>{(errors.mobile && touched.mobile) && errors.mobile}</small>
                </div>
                <div className="cont-input">
                    <textarea 
                        placeholder='Your Message' rows={5}
                        className={errors.message && touched.message ? "input-error": ""}
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        id='message'
                    />
                    <small>{(errors.message && touched.message) && errors.message}</small>
                </div>
                <button disabled={isSubmitting} type='submit' className='btn btn-danger '>
                    Send
                </button>
            </motion.div>
            </form>

            </div>
        </div>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </div>
  )
}

export default Contact
