import * as Yup from "yup";
// import "yup-phone";

export const basicSchemas= Yup.object().shape({
    name: Yup
        .string()
        .required("Name is required"),
    email: Yup.
        string()
        .email("Please enter a valid email")
        .required("Email is required"),
    mobile:Yup
    .string().required(),
    message:Yup
        .string()
        .required('message is Required')    
});

export const basicSchemas2= Yup.object().shape({
    name: Yup
        .string()
        .required("Name is required"),
    mobile:Yup
    .string().required(),
    message:Yup
        .string()
        .required('message is Required'),
    location:Yup
    .string()
    .required('location is Required')         
});

export const basicSchemas3= Yup.object().shape({
    name: Yup
        .string()
        .required("Name is required"),
    email: Yup.
        string()
        .email("Please enter a valid email")
        .required("Email is required"),
    feedback:Yup
        .string()
        .required('feedback is Required'),
    image:Yup
         .string()
        .notRequired()    
});