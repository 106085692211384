import './NotFound.css'
import Notfound from '../../assets/image/notfound.png'

const NotFound = () => {
  return (
    <div className="notFound">
     <div className='container pt-5'>
        <div className="not-main-content">
            <div className='not-content'>
                <span className="h1">Error 404</span>
                <span className="h5">not found page</span>
            </div>
            <div className="img-not-found">
                <img src={Notfound} alt="not found" />
            </div>
        </div>
     </div>
    </div>
  )
}

export default NotFound
