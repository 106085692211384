import './Whatsapp.css'
import WhatAppImg from '../../assets/image/what-app.png'
import { motion } from "framer-motion"
import { useEffect, useState } from 'react';
import axios from 'axios';



const Whatsapp = () => {
  const [loadData, setLoadData] = useState([]);  

  useEffect(()=>{
    loadContactData();
  },[]);
  
  const loadContactData= ()=> {
    axios.post('https://autocardoctor.co.nz/backend/whatsapp_data_load.php')
        .then(response => {
          setLoadData(response.data);
        })
         .catch(error => {
            console.log(error);
        });
  }

const company = `${loadData?.c_name};`
const message = `Hello, I found you on the website of ${company}. How can I get in touch?`;

const animationProps = {
  initial: {y:0, scale: 1},
  whileHover : {y:-20, scale: 1.16},
  transition: { duration: 0.3,type:"spring"}
};

  return (
    <motion.div {...animationProps} className='whats-app-icon'>
      <a
        href={`https://wa.me/${loadData?.whatsapp}?text=${encodeURIComponent(message)}`}
        target='_blank'
        rel='noopener noreferrer'
      >
        <img src={WhatAppImg} alt='WhatsApp' />
      </a>
    </motion.div>

  )
}

export default Whatsapp
