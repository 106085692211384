import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from './pages/home/Home';
import About from './pages/about/About';
import Contact from './pages/contactUs/Contact';
import ProductService from './pages/product-service/ProductService';
import Navbar from './components/navbar/Navbar';
import Footer from './components/footer/Footer';
import NotFound from './pages/notfound/NotFound';

function App() {
  return (
    <Router>
        <Navbar/>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/product-service/:id" component={ProductService} />

          {/* Route for the "Not Found" page */}
          <Route component={NotFound} />
        
        </Switch>
        <Footer/>
    </Router>
  );
}

export default App;
