import './Clients.css';
import Client1 from '../../assets/image/client-1.png'
import { motion } from "framer-motion"
import { useEffect, useState } from 'react';
import axios from 'axios';




const Clients = () => {
    const commonText = "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Fugiat explicabo tenetur est voluptatum aperiam eos, possimus ipsum";
    const animationCommon = {
        initial: { opacity: 0.6, y:75},
        whileInView: { opacity: 1 ,y:0,delay:0.3},
        transition: { duration: 1,},
      };

    const[clientData,setClientData] = useState([]); // to keep data got from db

    useEffect(()=>{
        loadClientData();
    },[]);

    const loadClientData = ()=> {
        axios.post('https://autocardoctor.co.nz/backend/client_data_load.php')
            .then(response => {
              setClientData(response.data);
            //   console.log(response.data);
            })
             .catch(error => {
                console.log(error);
            });
    }

  return (
    <section className="client-section py-5">
        <div className="container">

        <div className="row">
            <div className="sub-title">
                <span className='sub-title-title h3 fw-bold'>Our Client Say</span> 
                <div className='sub-title-bar'></div>
            </div>
        </div>

        <motion.div {...animationCommon} className="row">
            <div className="col-sm-12 col-md-4  client-card ">
                <div className="card" style={{width: '18rem'}}>
                    <img className="card-img-top client-card-img" src={clientData[0] ? process.env.PUBLIC_URL + clientData[0].img : Client1}  alt="Card image cap" />
                    <span className='client-name'>{clientData[0] ? clientData[0].name : "Name"}</span>
                    <div className="card-body">
                        <span>{clientData[0] ? clientData[0].feedback : commonText}</span>   
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4  client-card ">
                <div className="card" style={{width: '18rem'}}>
                    <img className="card-img-top client-card-img" src={clientData[1] ? process.env.PUBLIC_URL + clientData[1].img : Client1}  alt="Card image cap" />
                    <span className='client-name'>{clientData[1] ? clientData[1].name : "Name"}</span>
                    <div className="card-body">
                        <span>{clientData[1] ? clientData[1].feedback : commonText}</span>   
                    </div>
                </div>
            </div>
            <div className="col-sm-12 col-md-4  client-card ">
                <div className="card" style={{width: '18rem'}}>
                    <img className="card-img-top client-card-img" src={clientData[2] ? process.env.PUBLIC_URL + clientData[2].img : Client1}  alt="Card image cap" />
                    <span className='client-name'>{clientData[2] ? clientData[2].name : "Name"}</span>
                    <div className="card-body">
                        <span>{clientData[2] ? clientData[2].feedback : commonText}</span>   
                    </div>
                </div>
            </div>
        </motion.div>
        </div>
    </section>
  )
}

export default Clients
