import './service.css';
import { useEffect, useState } from 'react';
import { motion } from "framer-motion"
import axios from 'axios';
import { Link } from "react-router-dom";

const Service = () => {

  const [serviceDataNew, setServiceDataNew] = useState([]);  // declare serviceDataNew as a state variable

  useEffect(() => {
    loadServiceData();
  }, []);

  const loadServiceData = () => {
    axios.post('https://autocardoctor.co.nz/backend/service_data_load.php')
      .then(response => {
        setServiceDataNew(response.data);
      })
      .catch(error => {
        console.log(error);
      });
  }

  const [selected, setSelected] = useState(0);
  const [color, setColor] = useState('#eb9a9a');

  // to limit the number of letters in paragraph
  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + ' ...';
    } else {
      return text;
    }
  }

  // function to handle click events on span element
  function handleSpanClick(index) {
    setSelected(index);
    setColor('#eb9a9a'); // set the background color to blue
  }

  return (
    <section className='service-section py-5'>
      <div className="container">
        <div className="sub-title">
          <span className='sub-title-title h3 fw-bold'>Our Service</span>
          <div className='sub-title-bar'></div>
        </div>
        <div className="service-content">
          <div className="stage">
            {(serviceDataNew && serviceDataNew.length > 0) && serviceDataNew.map((data, index) => (
              <span
                key={index}
                onClick={() => { handleSpanClick(index) }}
                style={{ backgroundColor: index === selected ? color : '' }}
              >
                {truncateText(data.title, 30)}
              </span>
            ))}
          </div>

          <div className="stage-img">
            {(serviceDataNew && serviceDataNew.length > 0) && serviceDataNew[selected] && (
              <motion.img
                key={selected}
                initial={{ x: -100, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ opacity: 0, x: 100 }}
                transition={{ duration: 1, delay: 0.1 }}
                src={process.env.PUBLIC_URL + serviceDataNew[selected].img} alt='service-img' className='img-fluid' />
            )}
          </div>

          <motion.div
            key={selected}
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 3, delay: 0.1, type: "spring" }}
            className="stage-content"
          >
            {(serviceDataNew && serviceDataNew.length > 0) && serviceDataNew[selected] && (
              <>
                <span dangerouslySetInnerHTML={{ __html: truncateText(serviceDataNew[selected].pro_desc, 350) }}></span>
                <button className="btn btn-danger">
                  <Link to={`/product-service/${selected}`} className="nav-link">
                    Read More
                  </Link>
                </button>
              </>
            )}
          </motion.div>
        </div>
      </div>
    </section>
  )
}

export default Service;
