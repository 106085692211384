import './Footer.css'
import {FaFacebookF} from 'react-icons/fa'
import{BsInstagram} from 'react-icons/bs'
import {BsTwitter} from 'react-icons/bs'
import {BsLinkedin} from 'react-icons/bs'
import { useEffect, useState } from 'react'
import axios from 'axios'

const Footer = () => {

    const [loadData, setLoadData] = useState([]);  

    useEffect(()=>{
      loadContactData();
    },[]);
    
    const loadContactData= ()=> {
      axios.post('https://autocardoctor.co.nz/backend/contact_data_load.php')
          .then(response => {
            setLoadData(response.data);
          })
           .catch(error => {
              console.log(error);
          });
    }


  return (
    <section className='footer-section'>
        <div className="container footerr">
            <div className="sec-1">
                <span>Address:</span>
                <span className='mb-2'>{loadData.c_address}</span>
                <span>Contact:</span>
                <span>{loadData.c_mobile}</span>
                <span>{loadData.c_email}</span>
            </div>
            <div className="sec-2">
                <span>Follow Us On</span>
                <div className="social-icons">
                    <a href={loadData.facebook} target="_blank"> <FaFacebookF/></a>
                    <a href={loadData.instagram} target="_blank"> <BsInstagram/></a>
                    <a href={loadData.twitter} target="_blank"> <BsTwitter/></a>
                    <a href={loadData.linkedin} target="_blank"> <BsLinkedin/></a>
                </div>
            </div>
        </div>
         <div className="sec-3"></div>
        <div className='sec-4'>   
            <a href="" className='a-link'>Privacy Policy</a>
            <a href="" className='a-link'>Terms and Services</a>
            <a href="" className='a-link'>Cookies Setting</a>
        </div>
    </section>
  )
}

export default Footer
