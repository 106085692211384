import './Hero.css'
import { motion } from "framer-motion"



const Hero = ({mainTitle,subTitle,des,icon1,icon2,icon3,icon4,title1,title2,title3,title4}) => {
  const mobile = window.innerWidth <= 500 ? true : false;
  
  const animationProps = {
    initial: { opacity: 0, x:mobile ?-50:-150},
    animate: { opacity: 1 ,x:0, delay:0.3},
    transition: { duration: 2}
  };
  const animationProps2 = {
    initial: { opacity: 0, x:mobile?50:150},
    animate: { opacity: 1 ,x:0, delay:0.3},
    transition: { duration: 2}
  };

  const animationSoIcon1 = {
    initial: { opacity: 0, x:-100},
    whileInView: { opacity: 1 ,x:0, delay:0},
    transition: { duration: 0.5}
  }
  const animationSoIcon2 = {
    initial: { opacity: 0, x:-100},
    whileInView: { opacity: 1 ,x:0, delay:0.5},
    transition: { duration: 1}
  }
  const animationSoIcon3 = {
    initial: { opacity: 0, x:-100},
    whileInView: { opacity: 1 ,x:0, delay:1},
    transition: { duration: 1.5}
  }
  const animationSoIcon4 = {
    initial: { opacity: 0, x:-100},
    whileInView: { opacity: 1 ,x:0, delay:1.5},
    transition: { duration: 2}
  }

  

  return (
    <section className="hero">
      <div className="container">
        <div className="hero-section">
            <motion.div {...animationProps} className="main-title">
                <span>{mainTitle}</span>
            </motion.div>
            <motion.div  {...animationProps2}className="main-des">
                <span>{subTitle}</span>
                <span>{des}</span>
            </motion.div>
            <div className="social-icon">
              <motion.div {...animationSoIcon1}><span>{title1}</span> {icon1}</motion.div>
              <motion.div {...animationSoIcon2}><span>{title2}</span> {icon2}</motion.div>
              <motion.div {...animationSoIcon3}><span>{title3}</span> {icon3}</motion.div>
              <motion.div {...animationSoIcon4}><span>{title4}</span> {icon4}</motion.div>
            </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
