
import r2 from '../assets/image/slider1.png'
import r1 from '../assets/image/slider2.png'
import r3 from '../assets/image/slider3.png'

export const mainSliderData = [
    {   
        title: "We are..",
        img:r1,
        des:"At Auto Car Doctor NZ, we are your one-stop-shop for all your automobile service needs. Our team of experienced and certified technicians is dedicated to providing you with the highest quality service and care for your vehicle. We offer a wide range of services, including routine maintenance, as well as more complex electrical repairs like engine diagnostics and brake replacements. Our commitment to transparent communication and competitive pricing ensures that you know exactly what you're paying for and receive the best value for your money. Choose Auto Car Doctor NZ for all your automobile service needs, and experience the convenience and reliability of our services. Contact us today to schedule an appointment or learn more about our services."
    },
    {
        title:"Mobile Service",
        img:r2,
        des:"We provide a range of services, from routine maintenance. Our team of experienced and certified technicians is equipped with the latest tools and equipment to handle any job. One of the biggest advantages of using our mobile automobile service is the convenience it provides. Instead of wasting your valuable time driving to a garage and waiting for your car to be serviced, we come to you. Whether you're at home, at work, or even stuck on the side of the road, we can get to you quickly and get your car back on the road in no time."
    },
    {
        title:"24/7 Available",
        img:r3,
        des:"Our team of experienced and certified technicians is available around the clock to handle any emergency repair or maintenance needs. Whether you have a dead battery, or a more serious Electrical issue, we can come to you and get you back on the road as quickly as possible. We understand that car troubles can be stressful and even dangerous, especially if you're stranded on the side of the road. That's why we prioritize fast and reliable service to minimize any disruptions to your life and ensure your safety. In addition to emergency repairs, we also offer regular maintenance services 24/7, so you can keep your car in top condition no matter what time of day it is."
    }
];