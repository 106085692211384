 import Hero from '../../components/hero/Hero'
import './ProductService.css'
import Img24 from '../../assets/image/working-for-24.png'
import {MdOutlineArrowDropDown} from 'react-icons/md';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import { motion } from "framer-motion"

import { useFormik } from "formik"
import { basicSchemas2 } from "../../schemas/index.js";

import axios from 'axios';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";



 //alert for success update and error
 const notifySuccess = () => toast.success('Successfully Send!', {});
 const notifyError = () =>  toast.error('Something went wrong!', {});


const onSubmit = async (values, actions) => {
    // call api here
    let data = new FormData();
    data.append("name", values.name);
    data.append("mobile",values.mobile);
    data.append("message", values.message);
    data.append("location", values.location);
  
    axios.post('https://autocardoctor.co.nz/backend/emegency_form_submit.php', data)
    .then(response => {
      if(response.data === "Success"){
        notifySuccess();
      }else{
        notifyError();
      }
    })
    .catch(error => {
        console.log(error);
    });
  
  
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  };



const ProductService = () => {
    const mobile = window.innerWidth <= 500 ? true : false;
    const {id} = useParams();  // catch id
  
    const[serviceData,setServiceData] = useState([]); // to keep data got from db

    useEffect(()=>{
        loadProductServiceData();
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);

    const loadProductServiceData = ()=> {
        axios.post('https://autocardoctor.co.nz/backend/service_data_load.php')
            .then(response => {
              setServiceData(response.data);
            })
             .catch(error => {
                console.log(error);
             });
    }
    
    
    const animationProps = {
        initial: { opacity: 0, x: mobile?-30:-100},
        whileInView: { opacity: 1 ,x:0, delay:0.3},
        transition: { duration: 2}
      };

    const animationProps2 = {
        initial: { opacity: 0, x: mobile ? 30 :100},
        whileInView: { opacity: 1 ,x:0, delay:0.3},
        transition: { duration: 2}
      };

    const animationCommon = {
        initial: { opacity: 0.6, y:75},
        whileInView: { opacity: 1 ,y:0, dealy:0.3},
        transition: { duration: 1,}
    };

      const {values,handleChange,handleBlur,handleSubmit,errors,touched,isSubmitting} = useFormik({
        initialValues:{
            name:"",
            mobile:"",
            message:"",
            location:""
        },
        validationSchema: basicSchemas2,
        onSubmit,
    });

   

  return (
    <div className='product-service'>
        <Hero mainTitle="Product and Services"/>
        <section className="pro-ser">
            <div className="container">
                <div className="pro-ser-main">
                    <motion.div {...animationCommon} className="pro-ser-accordion">
                        <span>Our Services</span>
                        
                             <Accordion className='accordion py-5' allowZeroExpanded preExpanded={[Number(id)]} allowMultipleExpanded={false}>
                             {(serviceData && serviceData.length > 0) && serviceData.map((data,index)=>(
                                 <AccordionItem key={index} uuid={index}>
                                     <AccordionItemHeading>
                                         <AccordionItemButton className='accordionItemButton'>
                                             <span>{data.title}</span>
                                             <MdOutlineArrowDropDown size={45}/>
                                         </AccordionItemButton>
                                     </AccordionItemHeading>
 
                                     <AccordionItemPanel className='accordionItemPanel'>
                                         <motion.div 
                                              initial= { {opacity: 0,y:-10}}
                                              whileInView={ { opacity: 1 ,y:0} }
                                              transition= {{ duration: 0.3, type:'just'}}
                                         >
                                               <span dangerouslySetInnerHTML={{ __html: data.pro_desc }}></span>
                                         </motion.div>
                                     </AccordionItemPanel>
                                 </AccordionItem>
                             ))}
                             
                         </Accordion>
                      
                       
                    </motion.div>

                    <div className="twenty-four">
                        <span>24/7 breakdown service and jump-start</span>
                    </div>

                    <motion.div {...animationProps2} className="twenty-four-content">
                        <span>At  Auto Car Doctor NZ, we offer 24/7 breakdown service and jump-start automobile service to ensure you're covered no matter where you go. Our experienced technicians are available around the clock to provide you with the support you need. If you experience a breakdown, give us a call, and we'll dispatch a technician to your location to diagnose and repair the issue. And if you need a jump-start, we've got you covered. Trust us to provide you with the highest quality service, no matter the time of day or night. Contact us today to learn more about our services.</span>
                    </motion.div>

                    <div className="twenty-four-img">
                        <motion.img {...animationProps} src={Img24} alt="24-img" className='img-fluid'/>
                    </div>
                                
                    <form onSubmit={handleSubmit} autoComplete='off'>            
                    <motion.div {...animationCommon} className="contact-form">
                        <div className='cont-label'>
                            <span>Have an Emergency</span>
                            <span>send your details</span>
                        </div>

                        <div className='cont-input'>
                            <input 
                                type="text" 
                                placeholder='Name'
                                className={errors.name && touched.name ? "input-error": ""}
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id='name'
                            />
                            <small> {(errors.name && touched.name) && errors.name}</small>
                        </div>

                        <div className="cont-input">
                            <input 
                                type="text" 
                                placeholder='Mobile Number'
                                className={errors.mobile && touched.mobile ? "input-error": ""}
                                value={values.mobile}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id='mobile'
                            />
                            <small>{(errors.mobile && touched.mobile) && errors.mobile}</small>
                        </div>
                        <div className="cont-input">
                            <input 
                                type="text" 
                                placeholder='Your Location'
                                className={errors.location && touched.location ? "input-error": ""}
                                value={values.location}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id='location'
                            />
                            <small>{(errors.location && touched.location) && errors.location}</small>
                        </div>
                        <div className="cont-input">
                            <textarea 
                                placeholder='Your Message' rows={5}
                                className={errors.message && touched.message ? "input-error": ""}
                                value={values.message}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id='message'
                            />
                            <small>{(errors.message && touched.message) && errors.message}</small>
                        </div>
                        <button disabled={isSubmitting} type='submit' className='btn btn-danger '>
                             Send
                        </button>
                    </motion.div>
                    </form>
                </div>

                
                
            </div>
        </section>
        <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
        />
    </div>
  )
}

export default ProductService
