import React, { useState, useEffect } from 'react'
import './MainSlider.css';
import { mainSliderData } from '../../data/mainSliderData'
import { motion } from "framer-motion"
import { Link } from 'react-router-dom';

const MainSlider = () => {

  const animationProps = {
    initial: { opacity: 0, x:-150},
    animate: { opacity: 1 ,x:0, delay:1},
    transition: { duration: 2}
  };

  function truncateText(text, maxLength) {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + ' ...';
    } else {
      return text;
    }
  }

  const arrayLength = mainSliderData.length;
  const [length, setLength] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setLength((prev) => {
  //       if (prev === arrayLength - 1) {
  //         return 0;
  //       } else {
  //         return prev + 1;
  //       }
  //     });
  //   }, 5000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [arrayLength]);

  return (
    <section className='slider-section'>
      <div className="slider-div">
        <div className="slider-div-img">
          <motion.img 
            key={length}
            initial={{x:-100, opacity:0}}
            animate={{ x:0,opacity:1}}
            exit={{opacity:0, x:100}}
            transition={{duration: 3,delay:0.1, type:"spring", }}
          src={mainSliderData[length].img} className='img-fluid' alt="slide"  />
        </div>
        <motion.div 
           key={length}
           initial={{x:100, opacity:0}}
           animate={{ x:0,opacity:1}}
           exit={{opacity:0, x:-100}}
           transition={{duration: 3,delay:0.1, type:"spring", }}
        className="slider-div-content">
          <span className='mb-3'>{truncateText(mainSliderData[length].title, 15)}</span>
          <p>{truncateText(mainSliderData[length].des, 270)}</p>
          <button className='btn btn-danger mt-3 float-right'><Link className="nav-link" to="./about">Read More</Link></button>
        </motion.div>
        <div className="slider-click-arrow">
          <button
            onClick={()=>{
              if(length === 0){
                setLength((pre) => arrayLength-1)
              }else{
                setLength((pre)=> pre -1)
              }
            }}
          >&lt;</button>
          <button
            onClick={()=>{
              if(length === arrayLength-1){
                setLength((pre)=>  0)
              }else{
                setLength((pre)=> pre +1)
              }
            }}
          >&gt;</button>
        </div>
      </div>
    </section>
  )
}

export default MainSlider;
