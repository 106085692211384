import './Home.css'
import Clients from "../../components/clients/Clients"
import MainSlider from "../../components/main-slider/MainSlider"
import Navbar from "../../components/navbar/Navbar"
import Service from "../../components/servicesUs/Service"
import Technician from "../../components/technicians/Technician"
import CardImg1 from '../../assets/image/service-card-1.png'
import CardImg2 from '../../assets/image/service-card-2.png'
import CardImg3 from '../../assets/image/service-card-3.png'
import { motion } from "framer-motion"
import Feature1 from '../../components/feature/Feature1'

const Home = () => {

  const animationProps = {
    initial: { opacity: 0},
    animate: { opacity: 1 },
    transition: { duration: 2 ,type:"spring"}
  };

  const animationCommon = {
    initial: { opacity: 0.6, y:75},
    whileInView: { opacity: 1 ,y:0, dealy:0.3},
    transition: { duration: 1,}
  };
    

  return (
    <motion.div {...animationProps} className="home" >
        <MainSlider/>
          <section className="home-section">
            <div className="container py-5">
              <motion.div {...animationCommon} className="row">
                <div className=" col-12 col-md-12 col-lg-4 card-parent">
                  <div className="service-card">
                    <div className="img-div">
                      <img src={CardImg1} alt="card1" width={90} />
                    </div>
                    <div className="content-div">
                        <span>Quality Services </span>
                        <span>We are dedicated to providing exceptional services that exceed your expectations and ensure your complete satisfaction. With our team of highly skilled professionals and state-of-the-art facilities, we offer a comprehensive range of services to meet all your automotive needs </span>
                    </div>
                  </div>
                </div>

                <div className=" col-12 col-md-12 col-lg-4 card-parent">
                  <div className="service-card">
                    <div className="img-div">
                      <img src={CardImg2} alt="card1" width={90} />
                    </div>
                    <div className="content-div">
                        <span>Premium Care</span>
                        <span>Our Premium care is our standard. We understand that your vehicle is more than just a means of transportation; it's an investment that deserves exceptional care. That's why we are committed to providing you with premium services that go above and beyond to exceed your expectations.</span>
                    </div>
                  </div>
                </div>

                <div className=" col-12 col-md-12 col-lg-4 card-parent">
                  <div className="service-card">
                    <div className="img-div">
                      <img src={CardImg3} alt="card1" width={90} />
                    </div>
                    <div className="content-div">
                        <span>Customer Friendly</span>
                        <span>We strive to provide a customer-friendly experience that is both convenient and enjoyable. We believe that exceptional service starts with a strong focus on meeting your needs and exceeding your expectations. Customer satisfaction is our top priority. </span>
                    </div>
                  </div>
                </div>

              </motion.div> 
            </div>
          </section>
          <Service/>
        <Feature1/>
        <Technician/>
        <Clients/>
    </motion.div>
  )
}

export default Home
