import './Navbar.css'
import Logo from '../../assets/image/main-logo.png'
import { NavLink } from "react-router-dom";
import {FiMenu} from 'react-icons/fi'
import { useEffect, useState } from 'react';
import Whatsapp from '../whatsapp/Whatsapp';
import { motion } from "framer-motion"
import axios from 'axios';

const Navbar = () => {


const [logoPath, setLogoPath] = useState(Logo);  // to keep a logo path
const [clickKey, setClickKey] = useState(false)

const loadLogoData = ()=> {
  axios.post('https://autocardoctor.co.nz/backend/logo_path_load.php')
      .then(response => {
        setLogoPath(process.env.PUBLIC_URL+response.data.logo);
      })
       .catch(error => {
          console.log(error);
      });
}

  const mobile = window.innerWidth <= 768 ? true : false
 
  const [menuOpened, setMenuOpened] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  
   let animationProps2 = {
    key:mobile ? menuOpened : null,
    initial: { y: mobile ? -80 : 0},
    animate: {y:0},
    transition: { duration: 0.3},
  };
 
  const animationProps = {
    key:clickKey,
    initial: { opacity: 0.5, y:-50},
    animate: { opacity: 1 ,y:0, delay:0.3},
    transition: { duration: 2 ,type:"spring"},
  };

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }

    loadLogoData();  // get logo path
    window.addEventListener('resize', handleResize);

    // Clean up the event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const getMenuStyles = (menu)=>{
      if(screenWidth <= 768 && menu === false){
        return {display : "none"};
      }
  }

  
  return (

    <section className="navs">
      <div className="container">
      <div className='nav-section' >
        <Whatsapp/>

        <motion.div {...animationProps2} className=" navbarr" style={getMenuStyles(menuOpened)}>
            <span  className='nav-value'><NavLink className="nav-link" 
             onClick={() => {
              setClickKey(prev => !prev);
              setMenuOpened(prev => !prev);
             }} to="/">Home</NavLink></span>

            <span  className='nav-value'><NavLink className="nav-link"  onClick={() => {
              setClickKey(prev => !prev);
              setMenuOpened(prev => !prev);
             }} to={`/product-service/${0}`}>Product and Service</NavLink></span>

            < motion.img {...animationProps} src={logoPath} width={170} alt="logo" className='logo' />
            <span className='nav-value'><NavLink className="nav-link"  onClick={() => {
              setClickKey(prev => !prev);
              setMenuOpened(prev => !prev);
             }} to="/about">About Us</NavLink></span>
            <span className='nav-value'><NavLink className="nav-link"   onClick={() => {
              setClickKey(prev => !prev);
              setMenuOpened(prev => !prev);
             }}to="/contact">Contact Us</NavLink></span>
        </motion.div>

        <motion.div className="navbarr-mobile">
          <div ></div>
          <div>
            <motion.img {...animationProps} src={logoPath} width={150} alt="logo" className='logo' />
          </div>
          <div className='menu-icon'>
            <div onClick={()=> setMenuOpened((pre)=> !pre) }>
              <FiMenu size={32} color='#fff'/>
            </div>
          </div>
        </motion.div>
    </div>
      </div>
    </section>
  )
}

export default Navbar
