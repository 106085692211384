import React from 'react'
import './Feature1.css';
import { useEffect, useState } from 'react'
import { motion } from "framer-motion"
import axios from 'axios';

const Feature1 = () => {
  const mobile = window.innerWidth <= 500 ? true : false;

  const[featureData, setFeatureData] = useState([]); // to keep data gotten from db
   const arrayLength = featureData.length;
   const [length, setLength] = useState(0);

  //  load data from db
  useEffect(()=>{
    loadFeatureData();
  },[]);

  const loadFeatureData = ()=> {
    axios.post('https://autocardoctor.co.nz/backend/feature_data_load.php')
        .then(response => {
          setFeatureData(response.data);
        })
         .catch(error => {
            console.log(error);
        });
  }

   //set auto play
   useEffect(() => {
    const interval = setInterval(() => {
      setLength((prev) => {
        if (prev === arrayLength - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [arrayLength]);

  // to limit the number of letters in paragraph
  function truncateText(text, maxLength) {
   if (text.length > maxLength) {
     return text.slice(0, maxLength) + "...";
   } else {
     return text;
   }
 }

  return (
    <section className='feature-new pt-5'>
        <div className="feture-main-name h2">Special Features</div>
        <div className="container kep-gap"></div>
        <div className="container">
            <div className="row py-5">
                <div className="col-12 col-md-6">
                    <div className="img-div">
                        <div className="img-div-img">
                            {(featureData && featureData.length > 0 ) && (
                              <motion.img 
                              key={length}
                              initial={{x:mobile ? -30: -100, opacity:0}}
                              animate={{ x:0,opacity:1}}
                              exit={{opacity:0, x:100}}
                              transition={{duration: 3,delay:0.1, type:"spring", }}
                              src={featureData[length].img} alt="f-img" />
                            )}
                        </div>
                        <div className="img-bg">
                          {(featureData && featureData.length > 0 )&& <span className='h3'>{truncateText(featureData[length].title,15)}</span>}
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <motion.div
                     key={length}
                     initial={{x: mobile?30:100, opacity:0}}
                     animate={{ x:0,opacity:1}}
                     exit={{opacity:0, x:-100}}
                     transition={{duration: 3,delay:0.1, type:"spring", }}
                     className='div-content text-white '>
                        {(featureData && featureData.length > 0 )&& <span dangerouslySetInnerHTML={{ __html: truncateText(featureData[length].pro_desc, 450) }}></span>}
                    </motion.div>
                </div>
            </div>
        </div>

    </section>
  )
}

export default Feature1
