import './Technician.css';

import Img2 from '../../assets/image/tech-1.png'
import Img3 from '../../assets/image/tech-2.png'
import { motion } from "framer-motion"


const Technician = () => {
   
  const animationCommon = {
    initial: { opacity: 0.6, y:75},
    whileInView: { opacity: 1 ,y:0,delay:0.3},
    transition: { duration: 1,}
  };
    

  return (
    <section className='technician-section py-5'>
        <div  className="container">
            
            <div className="row">
                <div className="sub-title">
                    <div className='sub-title-bar'></div>
                    <span className='sub-title-title h3 fw-bold'>We also Provide</span> 
                </div>
            </div>

            <motion.div {...animationCommon} className="row">
                <div className="col-12 col-sm-12 col-md-6">
                    <div className="tech-card">
                        <img src={Img2} alt="card1" className='img-fluid' />
                        <span className='h5 fw-bold'>Break-down Services</span>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                    <div className="tech-card">
                        <img src={Img3} alt="card1" className='img-fluid' />
                        <span className='h5 fw-bold'>On-Site services</span>
                    </div>
                </div>
            </motion.div>
        </div>
    </section>
  )
}

export default Technician
