import './About.css';
import Hero from '../../components/hero/Hero'
import Img1 from '../../assets/image/about-page-img1.png'
import Img2 from '../../assets/image/about-page-img2.png'
import { motion } from "framer-motion"
import { useFormik } from "formik"
import { basicSchemas3 } from "../../schemas/index.js";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';


//alert for success update and error
const notifySuccess = () => toast.success('We accepted your feedback, THANK YOU!', {});
const notifyError = () =>  toast.error('Something went wrong!', {});
const notifyWarning = () =>  toast.warn('Please choose a JPG or JPEG or PNG file');

const onSubmit = async (values, actions) => {
    // call api here
    let data = new FormData();
    data.append("name", values.name);
    data.append("email", values.email);
    data.append("feedback",values.feedback);
    data.append("image",values.image);

   
    axios.post('https://autocardoctor.co.nz/backend/feedback_form_submit.php', data)
    .then(response => {
      if(response.data === "Success"){
        notifySuccess();
      }else if(response.data === "ErrorFormat"){
        notifyWarning();
      }
      else{
        notifyError();
      }
    })
    .catch(error => {
        console.log(error);
    });
  
  
    await new Promise((resolve) => setTimeout(resolve, 1000));
    actions.resetForm();
  };


const About = () => {
    const mobile = window.innerWidth <= 500 ? true : false;
    useEffect((e)=>{
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    },[]);


    const animationProps = {
        initial: { opacity: 0, x: mobile ?-30 : -100},
        whileInView: { opacity: 1 ,x:0, delay:0.3},
        transition: { duration: 2}
      };

    const animationProps2 = {
        initial: { opacity: 0, x: mobile ? 30 : 100},
        whileInView: { opacity: 1 ,x:0, delay:0.3},
        transition: { duration: 2}
      };

    const {values, handleChange,handleBlur,handleSubmit,errors,touched,isSubmitting, setFieldValue} = useFormik({
        initialValues:{
            name:"",
            email:"",
            feedback:"",
            image: null,
        },
        validationSchema: basicSchemas3,
        onSubmit,
    }); 
    
    // for catch image
    const handleImageChange = (event) => {
        const file = event.currentTarget.files[0];
        setFieldValue("image", file);
    };
    
    const des = `
    At Auto Car Doctor NZ, we are your one-stop-shop for all your automobile service needs. Our team of experienced and certified technicians is dedicated to providing you with the highest quality service and care for your vehicle.
    We offer a wide range of services, including routine maintenance, as well as more complex electrical repairs like engine diagnostics and brake replacements. Our commitment to transparent communication and competitive pricing ensures that you know exactly what you're paying for and receive the best value for your money.
    Choose Auto Car Doctor NZ for all your automobile service needs, and experience the convenience and reliability of our services. Contact us today to schedule an appointment or learn more about our services.`;
    
  return (

    <div  className='about'>
       <Hero mainTitle="About Us" subTitle="We are .." des={des}/>
       
       <section className='about-main'>
        <div className="info-div mobile-div">
            <span>Mobile Service</span>
            <span>
            We provide a range of services, from routine maintenance. Our team of experienced and certified technicians is equipped with the latest tools and equipment to handle any job.
            One of the biggest advantages of using our mobile automobile service is the convenience it provides. Instead of wasting your valuable time driving to a garage and waiting for your car to be serviced, we come to you. Whether you're at home, at work, or even stuck on the side of the road, we can get to you quickly and get your car back on the road in no time.
            </span>
        </div>

        <div className="info-div available-div">
            <span>24/7 Available</span>
            <span>
            Our team of experienced and certified technicians is available around the clock to handle any emergency repair or maintenance needs. Whether you have a dead battery, or a more serious Electrical issue, we can come to you and get you back on the road as quickly as possible.
            We understand that car troubles can be stressful and even dangerous, especially if you're stranded on the side of the road. That's why we prioritize fast and reliable service to minimize any disruptions to your life and ensure your safety.
            In addition to emergency repairs, we also offer regular maintenance services  24/7, so you can keep your car in top condition no matter what time of day it is.
            </span>
        </div>

        <div className="image-div-1">
            <motion.img {...animationProps} src={Img1} alt="about-img" className='img-fluid' />
        </div>

        <div className="image-div-2">
            <motion.img {...animationProps2} src={Img2} alt="about-img2" className='img-fluid' />
        </div>

        <div className="side-white-div white-div-1">
        </div>
        <div className="side-white-div white-div-2">
        </div>
        <div className="side-white-div white-div-3">
        </div>

       </section>
       
       <section className="about-feedback">
        <div className="container">
            <form onSubmit={handleSubmit} autoComplete='off'>
                <div className="feedback-form p-5">
                    <span>Give us your valuable feedback </span>
                    <div className="row mt-3">
                        <div className="col-md-6">
                            <div className="about-feed">
                                <input 
                                    type="text" 
                                    placeholder='Name'
                                    className={errors.name && touched.name ? "input-error": ""}
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id='name'
                                />
                                <small>{(errors.name && touched.name) && errors.name}</small>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-feed">
                                <textarea 
                                    placeholder='Your feedback'
                                    className={errors.feedback && touched.feedback ? "input-error": ""}
                                    value={values.feedback}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id='feedback'
                                />
                                <small>{(errors.feedback && touched.feedback) && errors.feedback}</small>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-feed">
                                <input 
                                    type="email" 
                                    placeholder='Email'
                                    className={errors.email && touched.email ? "input-error": ""}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id='email'
                                />
                                <small>{(errors.email && touched.email) && errors.email}</small>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="about-feed">
                                <label>
                                    Attach your photo here..
                                    <input 
                                        type="file"
                                        style={{display:"none"}}
                                        className={errors.image && touched.image ? "input-error" : ""}
                                        onChange={handleImageChange}  // use another method here
                                        onBlur={handleBlur}
                                        id="image"
                                        name="image"
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="about-feed-btn">
                                <button disabled={isSubmitting} type='submit' className='btn btn-danger btn-block'>Post</button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

       </section>
       <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
    </div>
  )
}

export default About
